@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  box-sizing: border-box;
}

:root {
  --primary-font: 'Poppins', sans-serif;
  /* --secondary-font: 'Work Sans', sans-serif; */
  --bg-color: #E6F4F4;
  --primary-color: #e455ef;
  --secondary-color: #771cd1;
  --text-main: #212121;
}


html {
  --scrollbarBG: #140915;
  --thumbBG: #e455ef;
  --gradBG: #140915;
}
body::-webkit-scrollbar {
  width: 12px;
  border-radius: 10px;
}
body {
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-image: linear-gradient(var(--thumbBG), var(--gradBG));
  border-radius: 10px;
  /* background-color: var(--thumbBG) ; */
}