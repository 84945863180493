.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
}
  
.nav-container {
    width: 85%;
    min-width: 285px;
    padding: 0.1rem 1rem;
    margin: 2.5rem auto;
    background-color: #ffe7fb;
    background-image: linear-gradient(315deg, #ffe7fb 0%, #e455ef 60%);
    box-shadow: 4px 4px 25px rgba(229, 85, 239, 0.34), inset -3px -2px 11px rgba(0, 0, 0, 0.22), inset 4px 4px 14px rgba(255, 255, 255, 0.25);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
  
.nav__text {
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
}

.nav__logos {
    display: flex;
    align-items: center;
    gap: 1rem;
}
  
.nav_excel_logo {
    cursor: pointer;
    transition: ease-in .2s;
    transform: scale(0.8);
}

.nav_excel_logo:hover {
    transform: scale(1);
}

.l2015 {
    width: 45px;
}

.l2016 {
    width: 45px;
}

.l2017 {
    width: 60px;
}
.l2018 {
    width: 38px;
}
.l2019 {
    width: 50px;
}
.l2020 {
    width: 50px;
}
.l2021 {
    width: 33px;
}
  

@media (min-width: 992px) and (max-width: 1380px) {

}


@media screen and (max-width: 992px) {
    .nav-container {
        width: 90%;
        padding: 0.1rem 0.5rem;
        margin: 3.5rem auto;
    }
      
    .nav__text {
        font-weight: 500;
        font-size: 1.2rem;
        margin-left: 0.5rem;
        white-space: nowrap;
        flex-shrink: 0;
    }
      
    .nav_excel_logo {
        transform: scale(0.7);
    }
}

@media screen and (max-width: 800px) {
    .nav-container {
        width: 96%;
        padding: 0.1rem 0.5rem;
        margin: 1rem auto;
    }
    .nav__logos {
        gap: 0.75rem;
    }


}

@media screen and (max-width: 600px) {
    .nav__text {
        display: none;
    }
    .nav__logos {
        gap: 0.1rem;
        justify-content: space-around;
        width: 100%;
    }
    .nav_excel_logo {
        transform: scale(0.5);
    }
    .nav_excel_logo:hover {
        transform: scale(0.8);
    }
}

@media screen and (max-width: 400px) {

}