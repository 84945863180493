.dashboard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 3rem;
    padding-top: 8rem;
    min-height: 100vh;
  }
  
  .dashboard_header {
    width: 100%;
  }
  
.story_desc {
    font-family: 'Poppins', sans-serif;
    margin: 2rem auto;
    font-style: normal;
    font-weight: 300;
    font-size: 1.35rem;
    color: #ffffff;
    text-align: center;
    width: 80%;
}
  
  
  
  
  .dashboard_body {
    width: 100%;
    margin: 2rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
  
  
  
  
  @media (min-width: 992px) and (max-width: 1380px) {
    .dashboard {
      padding: 2rem;
      padding-top: 7.5rem;
    }
  }
  
  @media screen and (max-width: 992px) {
    .dashboard {
      padding: 0rem;
      padding-top: 5rem;
    }
    .dashboard_body {
      margin: 0;
      gap: 1rem;
    }
  }
  
  @media screen and (max-width: 800px) {
    .dashboard_header {
      margin: 2rem auto 1rem;
    }
    .story_desc {
      margin: 1rem auto;
      font-size: 1.15rem;
      width: 100%;
      padding: 1rem;
    }
  }
  
  @media screen and (max-width: 600px) {
  }
  
  @media screen and (max-width: 400px) {
  }
  
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
  }
  
  @media only screen and (device-width: 768px) {
  }
  