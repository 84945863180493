.footer {
    height: 25vh;
    background-color: #0d1117;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
}

.footer_excel, .footer_mec {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer_img {
    cursor: pointer;
    width: 130px;
}

.footer_social {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.footer_social h2 {
    color: #ffffff;
    font-family: var(--primary-font);
    font-size: 1.25rem;
    font-weight: 500;
    margin-top: auto;
    text-align: center;
}

.social_icons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.25rem;
    margin-top: 2.25rem;
}

.footer_icon {
    font-size: 1.75rem;
    color: var(--secondary-color);
    transition: ease-in-out 0.2s;
}

.footer_icon:hover {
    color: var(--primary-color);
    transform: scale(1.2);
}


@media (min-width: 992px) and (max-width: 1380px) {
    .footer_img {
        width: 120px;
    }
}


@media screen and (max-width: 992px) {
    .footer_img {
        width: 110px;
    }
    .footer_social {

    }
    .footer_icon {
        font-size: 1.65rem;
    }
}

@media screen and (max-width: 800px) {
    .footer_img {
        width: 90px;
    }
    .footer_icon {
        font-size: 1.55rem;
    }
    .footer_social h2 {
        font-size: 1.05rem;
        margin-top: auto;
    }

}

@media screen and (max-width: 600px) {
    .footer {
        height: 20vh;
        padding: 0.75rem;
    }
    .footer_img {
        width: 70px;
    }
    .footer_social h2 {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 400px) {

}