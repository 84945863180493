.eventPage {
    min-height: 100vh;
    padding: 2rem;
    position: relative;
}

.go_home {
    cursor: pointer;
    color: var(--secondary-color);
    font-size: 1.65rem;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999999;
    background-color: #ffffff;
    box-sizing: content-box;
    padding: 0.5rem;
    border-radius: 50%;
}

.eventPage__container {
    width: 80%;
    margin: auto;
    margin-top: 8rem;
    margin-bottom: 2rem;
    background-color: var(--primary-color);
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 70px 15px 70px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 10px rgb(0 0 0 / 30%);
}

.eventPage__header {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.eventPage__details {
    flex: 0.5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: var(--primary-font);
    gap: 1rem;
}

.event__name {
    font-size: 3rem;
    background-color: var(--primary-color);
    background-image: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    position: relative;
    text-align: center;
}
.event__name:before {
    position: absolute;
    content: "";
    left: 25px;
    bottom: 8px;
    width: 50%;
    height: 2px;
    background-color: #cd48e9;
}
.event__name:after {
    position: absolute;
    content: "";
    left: 10px;
    bottom: 4px;
    width: 50%;
    height: 2px;
    background-color: #cd48e9;
}

.event__desc {
    font-size: 1.25rem;
    line-height: 140%;
    color: var(--text-main);
}

.eventPage__poster {
    flex: 0.5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.75rem;
}

.eventPage__poster img {
    width: 300px;
    border-radius: 10px;
}

.event_url {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.45rem;
    text-decoration: none;
    font-size: 1.15rem;
    font-weight: 600;
    color: var(--text-main);
    font-family: var(--primary-font);
    cursor: pointer;
}

.event_url:hover {
    color: var(--secondary-color);
}

.eventPage__gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    width: 100%;
}

.eventPage__gallery h1 {
    font-size: 1.75rem;
    color: var(--primary-color);
    font-family: var(--primary-font);
    font-weight: 500;
}

.eventGallery {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1.5rem;
}

.eventImg {
    border-radius: 5%;
    display: block;
    width: 60%;
    margin: 0 auto 10px;
    object-fit: fill;
}



@media (min-width: 992px) and (max-width: 1380px) {
    .eventPage {
        padding: 2rem;
    }
    
    .eventPage__container {
        width: 90%;
        margin-top: 8rem;
        padding: 2rem;
    }
}


@media screen and (max-width: 992px) {
    .eventPage {
        padding: 1rem;
    }
    
    .eventPage__container {
        width: 90%;
        margin-top: 8rem;
        padding: 2rem;
    }
}

@media screen and (max-width: 800px) {
    .eventPage {
        padding: 1rem;
    }
    
    .eventPage__container {
        width: 100%;
        margin-top: 10rem;
        padding: 1.5rem;
    }
    .eventPage__header {
        flex-direction: column;
        align-items: center;
        gap: 1.45rem;
    }

    .event__name {
        font-size: 2.5rem;
        line-height: 110%;
        padding-bottom: 1rem;
    }
    .event__desc {
        font-size: 1.15rem;
        line-height: 135%;
    }

    .event_url {
        font-size: 1.05rem;
    }
    .eventPage__gallery h1 {
        font-size: 1.55rem;
    }
    
    .eventImg {
        width: 90%;
    }
}

@media screen and (max-width: 600px) {
    .eventImg {
        width: 100%;
    }
}

@media screen and (max-width: 400px) {

}