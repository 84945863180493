.dashCard {
    box-sizing: border-box;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 268px;
    height: 181px;
    margin: 1.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    text-decoration: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    /* background: var(--secondary-color); */
    border: 5px solid var(--secondary-color);
    color: var(--secondary-color);
    background-image: linear-gradient(45deg, var(--secondary-color) 10%, transparent 70%);
    background-position: 100%;
    background-size: 400%;
    transition: background 300ms ease-in-out;
}


.dashCard:hover {
    background-position: 0;
    color: wheat;
}

  
.dashCardLevel {
    padding: 1.5rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.dashCardLevel > h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 1.35rem;
    color: #ffffff;
    text-align: center;
}
  
